/*
-----------------------------
Header
-----------------------------
*/
.site-header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 99;

	.top {
		background-color: $blue;
		padding: 0.5em 0;
		small {
			color: #fff;
			font-family: $boldRndFont;
			font-size: 10px;
			font-style: italic;
		}
	}

	.header-cont {
		padding: 1em 0;
		.container {
			display: flex;
			align-items: center;
			justify-content: space-between;
			.logo {
				width: 100px;
			}
		}
	}

	@media #{$break-xs} {
		.top {
			small {
				font-size: 13px;
			}
		}
	}

	@media #{$break-md} {
		.header-cont {
			.container {
				.logo {
					width: 130px;
				}
			}
		}
	}

	@media #{$break-lg} {
		.header-cont {
			.container {
				.logo {
					width: 150px;
				}
			}
		}
	}
}

/*
-----------------------------
Footer
-----------------------------
*/

.site-footer {
	background-color: darken($blue, 10%);
	padding: 3em 0 4em;
	color: #fff;
	font-size: 13px;
	.logo {
		img {
			display: block;
			max-width: 100%;
			height: auto;
			margin: auto;
		}
		p {
			font-family: $boldRndFont;
			font-size: 15px;
			text-align: center;
			letter-spacing: 0.015em;
			color: $orange;
			padding-top: 1em;
		}
	}
	header {
		font-family: $boldFont;
		font-size: 13px;
		letter-spacing: 0.015em;
		color: $skyblue;
		text-transform: uppercase;
		display: block;
		margin-bottom: 0.7em;
	}
	.social {
		a {
			text-decoration: none;
			color: #fff;
			display: inline-block;
			margin-right: 0.75em;
			&:hover,
			&:focus {
				color: $orange;
			}
		}
	}
	.links {
		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
			li {
				a {
					color: #fff;
					text-decoration: none;
					display: block;
					margin-bottom: 0.5em;
					display: flex;
					align-items: center;
					span {
						margin-left: 0.75em;
					}
					&:hover,
					&:focus {
						color: $orange;
						span {
							color: #fff;
						}
					}
				}
			}
		}
	}
}

.whatsapp-globe {
	position: fixed;
	bottom: 15px;
	right: 15px;
	z-index: 200;
	a {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0;
		width: 50px;
		height: 50px;
		background: #41ed7b;
		border-radius: 100px;
		color: $fontColor;
		transition: all 0.2s ease-out;
		span {
			display: none;
		}
		&:hover,
		&:focus {
			background: $fontColor;
			color: #41ed7b;
		}
	}
	@media #{$break-sm} {
		a {
			display: inline-flex;
			align-items: center;
			padding: 1em 1.3em 0.9em;

			line-height: 1em;
			text-decoration: none;
			font-size: 13px;
			width: inherit;
			height: inherit;
			span {
				margin-left: 10px;
				position: relative;
				display: block;
			}
		}
	}
}
