/*
-----------------------------
Fonts
-----------------------------
*/
$blackFont: "Gotham Black", serif;
$boldFont: "Gotham Bold", serif;
$bookFont: "Gotham Book", serif;
$bookRndFont: "Gotham Rounded Book", serif;
$boldRndFont: "Gotham Rounded Bold", serif;

/*
-----------------------------
Colores
-----------------------------
*/
$fontColor: #212122;
$blue: #0f6ed6;
$skyblue: #9fc5ea;
$orange: #ff8f29;
$gray: #94aed0;

/*
-----------------------------
Media queries
-----------------------------
*/
$break-xl: "(min-width: 1400px)";
$break-lg: "(min-width: 1200px)";
$break-md: "(min-width: 992px)";
$break-sm: "(min-width: 768px)";
$break-xs: "(min-width: 576px)";
$break-480: "(min-width: 480px)";
$break-320: "(min-width: 320px)";
