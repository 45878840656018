/*
-----------------------------
General
-----------------------------
*/

body {
	font-size: 16px;
	overflow-x: hidden;
	color: $fontColor;
	&.overhidden {
		overflow: hidden;
	}
}

.btn {
	&.btn-solid {
		display: inline-flex;
		align-items: center;
		font-family: $boldRndFont;
		font-size: 14px;
		line-height: 1em;
		color: #fff;
		background-color: $orange;
		padding: 0.9em 1.4em;
		svg {
			margin-left: 1em;
		}
		&:hover,
		&:focus {
			background-color: darken($orange, 8%);
		}
		&:active {
			color: #fff;
			border-color: darken($orange, 8%);
		}
	}
	&.btn-outline {
		display: inline-flex;
		align-items: center;
		font-family: $boldRndFont;
		font-size: 14px;
		line-height: 1em;
		color: $orange;
		padding: 0.9em 1.4em;
		border: 1px solid $orange;
		&:hover,
		&:focus,
		&:active {
			background-color: $orange;
			color: #fff;
		}
	}
}

.heading-s1 {
	.h-tag {
		display: block;
		font-family: $bookRndFont;
		font-size: 13px;
		line-height: 1em;
		letter-spacing: 0.02em;
		text-transform: uppercase;
		margin: 0 0 1em;
		color: $skyblue;
		&::before {
			content: "";
			display: inline-block;
			width: 9px;
			height: 9px;
			border-radius: 50%;
			background-color: $orange;
			margin-right: 1em;
		}
	}
	.h-title {
		font-family: $blackFont;
		font-size: 48px;
		font-size: clamp(28px, 5vw, 48px);
		line-height: 1.25em;
		color: $blue;
		&.xl {
			font-size: 64px;
			font-size: clamp(36px, 5vw, 64px);
		}
	}
	.lead {
		font-family: $bookRndFont;
		font-size: 26px;
		font-size: clamp(18px, 4vw, 26px);
		line-height: 1.5em;
		letter-spacing: 0.02em;
		color: $orange;
		strong {
			font-family: $boldRndFont;
		}
	}
}
