/*
-----------------------------
Fonts
-----------------------------
*/

@font-face {
	font-family: "Gotham Rounded Book";
	src: url("../fonts/GothamRnd-Book.woff2") format("woff2"),
		url("../fonts/GothamRnd-Book.woff") format("woff"),
		url("../fonts/GothamRnd-Book.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Gotham Rounded Bold";
	src: url("../fonts/GothamRnd-Bold.woff2") format("woff2"),
		url("../fonts/GothamRnd-Bold.woff") format("woff"),
		url("../fonts/GothamRnd-Bold.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Gotham Black";
	src: url("../fonts/Gotham-Black.woff2") format("woff2"),
		url("../fonts/Gotham-Black.woff") format("woff"),
		url("../fonts/Gotham-Black.ttf") format("truetype");
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Gotham Bold";
	src: url("../fonts/Gotham-Bold.woff2") format("woff2"),
		url("../fonts/Gotham-Bold.woff") format("woff"),
		url("../fonts/Gotham-Bold.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Gotham Book";
	src: url("../fonts/Gotham-Book.woff2") format("woff2"),
		url("../fonts/Gotham-Book.woff") format("woff"),
		url("../fonts/Gotham-Book.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

// @font-face {
// 	font-family: "Gotham Light";
// 	src: url("../fonts/Gotham-Light.woff2") format("woff2"),
// 		url("../fonts/Gotham-Light.woff") format("woff"),
// 		url("../fonts/Gotham-Light.ttf") format("truetype");
// 	font-weight: 300;
// 	font-style: normal;
// 	font-display: swap;
// }

/* @font-face {
	font-family: "Gotham Medium";
	src: url("../fonts/Gotham-Medium.woff2") format("woff2"),
		url("../fonts/Gotham-Medium.woff") format("woff"),
		url("../fonts/Gotham-Medium.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
} */

// @font-face {
// 	font-family: 'Gotham Rounded';
// 	src: url('../fonts/GothamRnd-BookItalic.woff2') format('woff2'),
// 		 url('../fonts/GothamRnd-BookItalic.woff') format('woff'),
// 		 url('../fonts/GothamRnd-BookItalic.ttf') format('truetype');
// 	font-weight: normal;
// 	font-style: italic;
// 	font-display: swap;
// }

// @font-face {
// 	font-family: 'Gotham Rounded';
// 	src: url('../fonts/GothamRnd-LightItalic.woff2') format('woff2'),
// 		 url('../fonts/GothamRnd-LightItalic.woff') format('woff'),
// 		 url('../fonts/GothamRnd-LightItalic.ttf') format('truetype');
// 	font-weight: 300;
// 	font-style: italic;
// 	font-display: swap;
// }

// @font-face {
// 	font-family: 'Gotham Rounded';
// 	src: url('../fonts/GothamRnd-BoldItalic.woff2') format('woff2'),
// 		 url('../fonts/GothamRnd-BoldItalic.woff') format('woff'),
// 		 url('../fonts/GothamRnd-BoldItalic.ttf') format('truetype');
// 	font-weight: bold;
// 	font-style: italic;
// 	font-display: swap;
// }

// @font-face {
// 	font-family: 'Gotham Rounded';
// 	src: url('../fonts/GothamRnd-Light.woff2') format('woff2'),
// 		 url('../fonts/GothamRnd-Light.woff') format('woff'),
// 		 url('../fonts/GothamRnd-Light.ttf') format('truetype');
// 	font-weight: 300;
// 	font-style: normal;
// 	font-display: swap;
// }

// @font-face {
// 	font-family: 'Gotham Rounded';
// 	src: url('../fonts/GothamRnd-Medium.woff2') format('woff2'),
// 		 url('../fonts/GothamRnd-Medium.woff') format('woff'),
// 		 url('../fonts/GothamRnd-Medium.ttf') format('truetype');
// 	font-weight: 500;
// 	font-style: normal;
// 	font-display: swap;
// }

// @font-face {
// 	font-family: 'Gotham Rounded';
// 	src: url('../fonts/GothamRnd-MediumItalic.woff2') format('woff2'),
// 		 url('../fonts/GothamRnd-MediumItalic.woff') format('woff'),
// 		 url('../fonts/GothamRnd-MediumItalic.ttf') format('truetype');
// 	font-weight: 500;
// 	font-style: italic;
// 	font-display: swap;
// }
